import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Dropdown } from 'sezane-components';

import { changeBrand as changeBrandAction } from '@actions/ui';
import routesNames from '@config/routesNames';
import { ReactComponent as OctobreLogoSmall } from '@img/logos/octobre-small.svg';
import { ReactComponent as OctobreLogo } from '@img/logos/octobre.svg';
import { ReactComponent as SezaneLogoSmall } from '@img/logos/sezane-small.svg';
import { ReactComponent as SezaneLogo } from '@img/logos/sezane.svg';
import { References } from '@types';
import { getBrandCodeFromLocation, getBrandUrl } from '@utils/brands';
import { useAppDispatch, useAppSelector } from '@utils/hooks/redux';

const brandsLogo = {
    sezane: SezaneLogo,
    sezane_phone: SezaneLogoSmall,
    octobre: OctobreLogo,
    octobre_phone: OctobreLogoSmall,
};

type logoKey = 'sezane' | 'octobre' | 'sezane_phone' | 'octobre_phone';

const allowedRoutes = [
    routesNames.LIST_SALES,
    routesNames.LIST_PRODUCTS,
    routesNames.LIST_MULTI_PRODUCTS,
    routesNames.MEDIA_LIBRARY,
    routesNames.LIST_CUSTOMISATION_SERVICES,
    routesNames.LIST_PREORDERS,
    routesNames.LIST_PAGES,
    routesNames.LIST_COMPOSITION_LAYOUTS,
    routesNames.MAIN_MENU,
    routesNames.LIST_MOBILE_CONTENTS,
    routesNames.FOOTER,
    routesNames.LIST_WORKSPACES,
    routesNames.UPDATE_MANDATORY_LOGIN,
    routesNames.LIST_PARAMETERS,
    routesNames.LIST_POPINS,
    routesNames.LIST_EVERGREEN_MEDIAS,
    routesNames.LIST_PACKAGINGS,
    routesNames.LIST_PUSH_PRODUCTS,
    routesNames.DASHBOARD,
];

const isAllowedToSwitchBrand = (path: string) => matchPath(path, allowedRoutes)?.isExact;

interface BrandSwitcherProps {
    collapsed: boolean;
}

export const BrandSwitcher = ({ collapsed }: BrandSwitcherProps) => {
    const brands = useAppSelector(state => state.references.brands);
    const currentBrand = useAppSelector(state => state.ui.brand);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const logoName: logoKey = collapsed ? (`${currentBrand.code}_phone` as logoKey) : (currentBrand.code as logoKey);
    const Logo = brandsLogo[logoName];

    const changeBrand = (brand: References.Brand) => dispatch(changeBrandAction(brand));

    const onSelect = (brand: References.Brand) => {
        if (brand.code === currentBrand.code) {
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            changeBrand(brand);
        } else {
            const url = new URL(window.location.href);
            url.hostname = getBrandUrl(window.location.hostname, brand.code);
            url.searchParams.delete('workspace');
            window.location.href = url.toString();
        }
    };

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            const brandCode = getBrandCodeFromLocation(window.location.href);
            const switchedBrand = brands.find(brand => brand.code === brandCode);

            if (switchedBrand && switchedBrand.code !== currentBrand.code) {
                changeBrand(switchedBrand);
            }
        }
    }, [window.location.href]);

    return (
        <Dropdown
            disabled={!isAllowedToSwitchBrand(location.pathname)}
            id="header-brand-switcher"
            color="unstyled"
            textSecondary
            position="left"
            size="xs-bis"
            className="c-header__dropdown c-page__header-dropdown u-mt(xs) u-z-index-30"
            triggerClassName="c-header__dropdown-trigger"
            label={
                <h1 className="c-logo u-mt(sm)">
                    <Logo />
                </h1>
            }
            options={brands.map(brand => ({
                onSelect: () => onSelect(brand),
                renderOption: () => brand.label,
            }))}
            selected={brands.findIndex(brand => brand.code === currentBrand.code)}
        />
    );
};

export default BrandSwitcher;
