import { FiltersParams, SortParams } from '@types';
import { listActionCreator, withNoBrand, withNoSite, withPage, withRequestParams, withValues } from '../../crud';

import { baseUrl, name, schema } from './config';
import { addOnlyTrueFilter } from '@utils/filters';

const { types, actionCreator } = listActionCreator(name, schema, baseUrl);
export const TYPES = types;

export type ShopsListFilters = FiltersParams & {
    enabled?: boolean;
};

export default (
    page = 1,
    sort: SortParams | null = null,
    filters: ShopsListFilters | null = null,
    query: object | null = null,
    withoutPagination = false
) => {
    const sortParam = sort ? { [`order[${sort.field}]`]: sort.direction } : {};

    const params = {};
    addOnlyTrueFilter('enabled', !!filters?.enabled, params);

    const action = withValues({
        filters,
        query,
    })(
        withRequestParams({
            description: query || undefined,
            ...sortParam,
            ...params,
        })(withNoBrand(withNoSite(actionCreator())))
    );

    return withoutPagination ? action : withPage(page)(action);
};
