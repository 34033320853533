/* eslint-disable camelcase */
import uniq from 'lodash/uniq';

export const REGULAR_VIEW = 'regular';
export const EDITORIAL_VIEW = 'editorial';
export const FINAL_PRICE_VIEW = 'final_price';
export const BULLET_POINTS_VIEW = 'bullet_points';
export const CUSTOM_VIEW = 'custom';
export const VIEWS = [REGULAR_VIEW, EDITORIAL_VIEW, FINAL_PRICE_VIEW, BULLET_POINTS_VIEW];
export const DEFAULT_VIEW = REGULAR_VIEW;

const PERMANENT_FIELDS = {
    product: true,
};

export const REGULAR_VIEW_PRESET = {
    ...PERMANENT_FIELDS,
    stock_web: true,
    stock_web_cart: true,
    stock_logistic: true,
    implementation_date: true,
    sales: true,
    made_in: false,
    made_in_fr: false,
    made_in_en: false,
    composition: false,
    composition_fr: false,
    composition_en: false,
    additional_information: false,
    additional_information_fr: false,
    additional_information_en: false,
    display_care: false,
    display_care_fr: false,
    display_care_en: false,
    status: true,
    translation_status: true,
};

export const EDITORIAL_VIEW_PRESET = {
    ...PERMANENT_FIELDS,
    edito: true,
};

// dynamically set in reducer
export const FINAL_PRICE_VIEW_PRESET = {
    ...PERMANENT_FIELDS,
};

// dynamically set in reducer
export const BULLET_POINTS_VIEW_PRESET = {
    ...PERMANENT_FIELDS,
};

export const ADD_MODAL_VIEW_PRESET = {
    ...PERMANENT_FIELDS,
    stock_web: true,
    stock_web_cart: true,
    stock_logistic: true,
};

export const PRESETS = {
    [REGULAR_VIEW]: REGULAR_VIEW_PRESET,
    [EDITORIAL_VIEW]: EDITORIAL_VIEW_PRESET,
    [FINAL_PRICE_VIEW]: FINAL_PRICE_VIEW_PRESET,
    [BULLET_POINTS_VIEW]: BULLET_POINTS_VIEW_PRESET,
};

export const getExportableFields = (configuration: Record<string, boolean>, view: string, currencies: string[]) => {
    const fields = Object.keys(configuration).filter(field => configuration[field]);

    if (view === REGULAR_VIEW) {
        currencies.forEach(currency => {
            fields.push(`price_${currency}`);
            fields.push(`crossed_out_price_${currency}`);
        });
        fields.push('sales');
        fields.push('first_sale_date');
        fields.push('last_sale_date');
    }

    if (view === EDITORIAL_VIEW) {
        fields.push('name_fr');
        fields.push('name_en');
        fields.push('color_fr');
        fields.push('color_en');
        currencies.forEach(currency => {
            fields.push(`price_${currency}`);
            fields.push(`crossed_out_price_${currency}`);
        });
        fields.push('pim_size_advice');
    }

    return uniq(fields);
};

export default (availableLocales: string[], availableCurrencies: string[]) => {
    const fields: Record<string, string[] | null> = {
        implementation_date: null,
        first_sale_date: null,
        last_sale_date: null,
        sales: null,
        status: null,
        translation_status: null,
        stock_web: null,
        stock_web_cart: null,
        stock_logistic: null,
        prices: null,
        edito: null,
        additional_desc: null,
        bullet_points: null,
        size_advice: null,
        pim_size_advice: null,
        size_advice_type: null,
        made_in: null,
        composition: null,
        additional_information: null,
        display_care: null,
        cross_sell: null,
        cross_sell_popin: null,
        cross_sell_popin_product: null,
    };

    const prices: string[] = [];
    availableCurrencies.forEach(currency => {
        prices.push(`price_${currency}`);
        prices.push(`crossed_out_price_${currency}`);
    });
    fields.prices = [...prices, 'price_in_workspace', 'potential_site_turnover', 'potential_web_turnover'];

    const filteredLocales = availableLocales.filter(locale => locale !== 'ko' && locale !== 'zh');

    fields.made_in = filteredLocales.map(locale => `made_in_${locale}`);
    fields.composition = filteredLocales.map(locale => `composition_${locale}`);
    fields.additional_information = filteredLocales.map(locale => `additional_information_${locale}`);
    fields.display_care = filteredLocales.map(locale => `display_care_${locale}`);
    fields.edito = filteredLocales.map(locale => `edito_${locale}`);
    fields.additional_desc = filteredLocales.map(locale => `additional_desc_${locale}`);
    fields.bullet_points = filteredLocales.map(locale => `bullet_points_${locale}`);
    fields.size_advice = filteredLocales.map(locale => `size_advice_${locale}`);

    return fields;
};
