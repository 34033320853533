import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeListConfiguration, changeListView } from '@actions/storeVariants/configuration';
import { Col, Grid } from 'sezane-components';
import { ButtonBar } from 'sezane-components';
import FieldsConfiguration from '../../configuration';
import ListViewToggle from './ListViewToggle';
import build, { DEFAULT_VIEW } from './builder';

const Configuration = props => {
    const {
        locales,
        currencies,
        configuration,
        view,
        changeListView,
        changeListConfiguration,
        showExtrasButton,
        children,
    } = props;
    const fields = build(locales, currencies);

    const toggleConfigurationValue = (fieldKey, children, parentKey) => {
        const values = {};
        const value = !configuration[fieldKey];
        values[fieldKey] = value;
        if (children) {
            children.forEach(child => {
                values[child] = value;
            });
        }
        if (parentKey && !value) {
            values[parentKey] = false;
        }

        changeListConfiguration(values);
    };

    return (
        <Grid className="u-ai(center) u-jc(space-between) u-mb(xxs)">
            <Col>
                <ListViewToggle view={view} changeListView={changeListView} />
            </Col>
            {children && <Col>{children}</Col>}
            <Col className="u-ml(auto)">
                <ButtonBar>
                    {showExtrasButton}
                    <FieldsConfiguration
                        configuration={configuration}
                        fields={fields}
                        toggleField={toggleConfigurationValue}
                        limit
                    />
                </ButtonBar>
            </Col>
        </Grid>
    );
};

Configuration.propTypes = {
    configuration: PropTypes.object.isRequired,
    view: PropTypes.string.isRequired,
    changeListConfiguration: PropTypes.func.isRequired,
    changeListView: PropTypes.func.isRequired,
    showExtrasButton: PropTypes.node,
    locales: PropTypes.array.isRequired,
    currencies: PropTypes.array.isRequired,
    children: PropTypes.node,
};

Configuration.defaultProps = {
    showGroupedActions: () => {},
};

const mapStateToProps = state => ({
    view: state.storeVariants.listView || DEFAULT_VIEW,
});

const mapDispatchToProps = dispatch => ({
    changeListConfiguration: values => dispatch(changeListConfiguration(values)),
    changeListView: view => {
        return dispatch(changeListView(view));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
